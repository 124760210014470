<!-- priorityPublication -->
<template>
  <div v-loading="loading" class="priorityPublication">
    <listTable
      show-authors
      show-mag-cn
      show-keywords
      show-first-pub-time
      :table-data="tableData"
      :table-page-obj="tablePageObj"
      :table-column="tableColumn"
      :total-data-length="totalDataLength"
      asc-string="firstPubTime,ASC"
      desc-string="firstPubTime,DESC"
      data-track-text="增值服务-文献聚合-优先出版文章"
      @getTableData="getTableData"
      @handleOpenUrl="handleOpenUrl"
      @changeSort="changeSort"
      @changeChooseTime="changeChooseTime"
      @handleChangePageData="changePageData"
    ></listTable>
  </div>
</template>

<script>
import listTable from '@comp/listTable'
import { mapState } from 'vuex'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.contentAggregation
export default {
  name: 'PriorityPublication',
  data() {
    return {
      loading: true,
      tableData: [],
      tablePageObj: {
        page: 1,
        size: 10,
        sort: 'firstPubTime,DESC'
      },
      tableColumn: [
        {
          title: '论文标题',
          prop: 'title',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '刊物名称',
          prop: 'magCn',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '作者机构',
          prop: 'institutions',
          notSortable: true
        },
        {
          title: '发表时间',
          prop: 'firstPubTime',
          notSortable: true
        }
      ],
      totalDataLength: null
    }
  },
  props: {},
  components: { listTable },
  computed: { ...mapState('incrementService', ['magId']) },
  mounted() {
    if (!this.magId) {
      this.loading = false
    }
  },
  methods: {
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const { startTime = '', endTime = '' } = this.getTimePeriod(
        this.chooseTime
      )
      const params = {
        // id: 2817,
        id: this.magId,
        page: page - 1,
        size,
        sort: this.tablePageObj.sort,
        startTime: startTime,
        endTime: endTime
      }
      let url = api.GetFirstArticle.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.tablePageObj = {
            page,
            size,
            sort: this.tablePageObj.sort,
            total: totalElements
          }
          this.tableData = content
          this.tableData.map((item) => {
            item.abstract = item.abstractContent
          })
          this.totalDataLength = totalElements
        }
      })
    },
    handleOpenUrl(chooseItem) {
      const { url = '', officialWebsiteUrl = '' } = chooseItem
      if (officialWebsiteUrl && officialWebsiteUrl !== '') {
        window.open(officialWebsiteUrl)
      } else if (url && url !== '') {
        window.open(url)
      }
    },
    changeSort(value) {
      this.tablePageObj.sort = value
      this.getTableData()
    },
    changeChooseTime(chooseTime) {
      this.chooseTime = chooseTime
      this.getTableData()
    },
    changePageData(pageObj) {
      this.tablePageObj.page = pageObj.page
      this.getTableData(pageObj)
    },
    // 开始和结束时间字符串的生成
    getTimePeriod(dayNum = 0) {
      const nowDate = new Date()
      const date = new Date(nowDate)
      date.setDate(date.getDate() - dayNum)
      const startTime = this.changeDateToString(date)
      const endTime = this.changeDateToString(nowDate)
      return {
        startTime,
        endTime
      }
    },
    // 处理date为字符串
    changeDateToString(date = new Date()) {
      const seperator = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentDate = year + seperator + month + seperator + strDate
      return currentDate
    }
  }
}
</script>

<style lang="scss" scoped></style>
