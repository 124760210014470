<template>
  <div class="listTable">
    <div class="header">
      <div class="left">
        <div class="total">
          共计<span>{{ totalDataLength }}</span
          >条记录
        </div>
        <div class="sort">
          <span> 排序：发表时间</span>
          <img
            :data-track="`${dataTrackText}-发表时间-升序`"
            :src="ascSort"
            @click="changeSort(ascString)"
          />
          <img
            :data-track="`${dataTrackText}-发表时间-降序`"
            :src="descSort"
            @click="changeSort(descString)"
          />
        </div>
      </div>
      <div class="right">
        <div class="select">
          <span>统计时间：</span>
          <el-select
            v-model="chooseTime"
            placeholder="请选择"
            @change="changeChooseTime"
            :data-track="`${dataTrackText}-统计时间-${chooseTimeLabel}`"
          >
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="list-table">
          <img :src="listImg" @click="changeType('list')" />
          <img :src="tableImg" @click="changeType('table')" />
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="details">
      <div class="list" v-if="listTableType === 'list'">
        <div v-if="tableData.length > 0">
          <div
            v-for="(item, index) in tableData"
            :key="index"
            class="list-item"
          >
            <div class="check-title">
              <!-- <div class="check">
                <el-checkbox
                  v-model="item.id"
                  @change="checkChange"></el-checkbox>
              </div> -->
              <div
                class="title"
                v-if="item.title"
                v-html="
                  (tablePageObj.page - 1) * tablePageObj.size +
                  index +
                  1 +
                  '.' +
                  item.title
                "
                @click="handleOpenUrl(item)"
              ></div>
            </div>

            <div class="info-details">
              <div
                class="authors"
                v-if="item.authors && showAuthors"
                v-html="'作者：' + item.authors"
              ></div>
              <div
                class="magCn"
                v-if="item.magCn && showMagCn"
                v-html="'期刊：' + item.magCn"
              ></div>
              <div
                class="siteName"
                v-if="item.siteName && showSiteName"
                v-html="'来源：' + item.siteName"
              ></div>
              <div
                class="channelName"
                v-if="item.channelName && showChannelName"
                v-html="'频道：' + item.channelName"
              ></div>
              <div
                class="issueTime"
                v-if="item.issueTime && showIssueTime"
                v-html="'发布时间：' + item.issueTime"
              ></div>
            </div>
            <div
              class="abstract ell2"
              v-if="item.abstract"
              v-html="item.abstract"
            ></div>
            <div class="keywords-firstPubTime">
              <div
                class="keywords"
                v-if="item.keywords && showKeywords"
                v-html="'关键词：' + item.keywords"
              ></div>
              <div
                class="firstPubTime"
                v-if="item.firstPubTime && showFirstPubTime"
                v-html="'发表时间：' + item.firstPubTime"
              ></div>
            </div>
          </div>
          <Pagination
            :show-total="false"
            :init-obj="tablePageObj"
            @onChangePageData="handleChangePageData"
          />
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>
      <commonTable
        v-if="listTableType === 'table'"
        :is-click="false"
        :show-table-pagination="false"
        :table-data="tableData"
        :column-data="tableColumn"
        :init-obj="tablePageObj"
        @onClickTitle="handleOpenUrl"
        @onChangePageData="
          (pageObj) => {
            handleChangePageData(pageObj)
          }
        "
      ></commonTable>
    </div>
  </div>
</template>

<script>
import commonTable from '@comp/commonTable'
import Pagination from '@comp/pagination'
import { mapState } from 'vuex'
export default {
  name: 'PriorityPublication',
  data() {
    return {
      chooseTime: 7,
      timeOptions: [
        {
          value: 7,
          label: '最近一周'
        },
        {
          value: 30,
          label: '最近一个月'
        },
        {
          value: 92,
          label: '最近三个月'
        },
        {
          value: 183,
          label: '最近半年'
        }
      ],
      listTableType: 'list',
      sortAscActive: require('@assets/img/sort-asc-active.png'),
      sortAsc: require('@assets/img/sort-asc.png'),
      sortDescActive: require('@assets/img/sort-desc-active.png'),
      sortDesc: require('@assets/img/sort-desc.png'),
      listActive: require('@assets/img/list-active.png'),
      list: require('@assets/img/list.png'),
      tableActive: require('@assets/img/table-active.png'),
      table: require('@assets/img/table.png')
    }
  },
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    tablePageObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tableColumn: {
      type: Array,
      default: () => {
        return []
      }
    },
    totalDataLength: {
      type: Number,
      default: 0
    },
    ascString: {
      type: String,
      default: ''
    },
    descString: {
      type: String,
      default: ''
    },
    showAuthors: {
      type: Boolean,
      default: false
    },
    showMagCn: {
      type: Boolean,
      default: false
    },
    showSiteName: {
      type: Boolean,
      default: false
    },
    showChannelName: {
      type: Boolean,
      default: false
    },
    showIssueTime: {
      type: Boolean,
      default: false
    },
    showKeywords: {
      type: Boolean,
      default: false
    },
    showFirstPubTime: {
      type: Boolean,
      default: false
    },
    dataTrackText: {
      type: String,
      default: ''
    }
  },
  components: { commonTable, Pagination },
  computed: {
    ...mapState('incrementService', ['magId']),
    ascSort() {
      return this.tablePageObj.sort === this.ascString
        ? this.sortAscActive
        : this.sortAsc
    },
    descSort() {
      return this.tablePageObj.sort === this.descString
        ? this.sortDescActive
        : this.sortDesc
    },
    listImg() {
      return this.listTableType === 'list' ? this.listActive : this.list
    },
    tableImg() {
      return this.listTableType === 'table' ? this.tableActive : this.table
    },
    chooseTimeLabel() {
      let text = ''
      text = this.timeOptions.filter((item) => {
        return item.value === this.chooseTime
      })[0].label
      return text
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.magId) {
        this.changeChooseTime()
      }
    })
  },
  watch: {
    magId() {
      this.$nextTick(() => {
        this.changeChooseTime()
      })
    }
  },
  methods: {
    handleOpenUrl(chooseItem) {
      this.$emit('handleOpenUrl', chooseItem)
    },
    changeSort(value) {
      this.$emit('changeSort', value)
    },
    changeType(value) {
      this.listTableType = value
    },
    changeChooseTime() {
      this.$emit('changeChooseTime', this.chooseTime)
    },
    handleChangePageData(pageObj) {
      this.$emit('handleChangePageData', pageObj)
    },
    checkChange(value) {
      console.log(value, '000')
    }
  }
}
</script>
<style lang="scss">
.listTable {
  .list-item {
    .check-title {
      .el-checkbox__input {
        height: 24px;
        line-height: 24px;
      }
    }
  }
  .el-select {
    font-size: 12px;
    color: #333333;
    width: 100px;
    .el-input__inner {
      height: 28px;
    }
  }
  .abstract img {
    max-width: 300px;
    display: block;
    margin: auto;
  }
}
</style>
<style lang="scss" scoped>
.listTable {
  .header {
    padding: 14px;
    font-size: 14px;
    color: #404040;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .total {
        span {
          margin: 0 4px;
        }
      }
      .sort {
        margin-left: 10px;
        display: flex;
        align-items: center;
        span {
          margin-right: 4px;
        }
        img {
          width: 25px;
          cursor: pointer;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .select {
        margin-right: 8px;
        span {
          margin-right: 4px;
        }
      }
      .list-table {
        img {
          margin: 0 8px;
          cursor: pointer;
        }
      }
    }
  }
  .line {
    background: #f0f2f5;
    height: 10px;
  }
  .details {
    padding: 20px;
    .list {
      font-size: 14px;
      line-height: 24px;
      .list-item {
        padding: 20px 0;
        border-bottom: 1px solid #ebeef5;
        .check-title {
          display: flex;
          align-items: center;
        }
        .check {
          margin: 0 10px 0 0;
        }
        .title {
          font-size: 16px;
          color: #303133;
          cursor: pointer;
        }
        .info-details,
        .keywords-firstPubTime {
          color: #999;
          display: flex;
          .channelName,
          .issueTime,
          .magCn,
          .firstPubTime {
            margin-left: 20px;
          }
        }
        .abstract {
          margin-top: 10px;
          color: #666666;
        }
      }
    }
  }
  .no-data {
    margin: auto;
    text-align: center;
  }
}
</style>
