var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listTable"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"total"},[_vm._v(" 共计"),_c('span',[_vm._v(_vm._s(_vm.totalDataLength))]),_vm._v("条记录 ")]),_c('div',{staticClass:"sort"},[_c('span',[_vm._v(" 排序：发表时间")]),_c('img',{attrs:{"data-track":`${_vm.dataTrackText}-发表时间-升序`,"src":_vm.ascSort},on:{"click":function($event){return _vm.changeSort(_vm.ascString)}}}),_c('img',{attrs:{"data-track":`${_vm.dataTrackText}-发表时间-降序`,"src":_vm.descSort},on:{"click":function($event){return _vm.changeSort(_vm.descString)}}})])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"select"},[_c('span',[_vm._v("统计时间：")]),_c('el-select',{attrs:{"placeholder":"请选择","data-track":`${_vm.dataTrackText}-统计时间-${_vm.chooseTimeLabel}`},on:{"change":_vm.changeChooseTime},model:{value:(_vm.chooseTime),callback:function ($$v) {_vm.chooseTime=$$v},expression:"chooseTime"}},_vm._l((_vm.timeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"list-table"},[_c('img',{attrs:{"src":_vm.listImg},on:{"click":function($event){return _vm.changeType('list')}}}),_c('img',{attrs:{"src":_vm.tableImg},on:{"click":function($event){return _vm.changeType('table')}}})])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"details"},[(_vm.listTableType === 'list')?_c('div',{staticClass:"list"},[(_vm.tableData.length > 0)?_c('div',[_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('div',{staticClass:"check-title"},[(item.title)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
                (_vm.tablePageObj.page - 1) * _vm.tablePageObj.size +
                index +
                1 +
                '.' +
                item.title
              )},on:{"click":function($event){return _vm.handleOpenUrl(item)}}}):_vm._e()]),_c('div',{staticClass:"info-details"},[(item.authors && _vm.showAuthors)?_c('div',{staticClass:"authors",domProps:{"innerHTML":_vm._s('作者：' + item.authors)}}):_vm._e(),(item.magCn && _vm.showMagCn)?_c('div',{staticClass:"magCn",domProps:{"innerHTML":_vm._s('期刊：' + item.magCn)}}):_vm._e(),(item.siteName && _vm.showSiteName)?_c('div',{staticClass:"siteName",domProps:{"innerHTML":_vm._s('来源：' + item.siteName)}}):_vm._e(),(item.channelName && _vm.showChannelName)?_c('div',{staticClass:"channelName",domProps:{"innerHTML":_vm._s('频道：' + item.channelName)}}):_vm._e(),(item.issueTime && _vm.showIssueTime)?_c('div',{staticClass:"issueTime",domProps:{"innerHTML":_vm._s('发布时间：' + item.issueTime)}}):_vm._e()]),(item.abstract)?_c('div',{staticClass:"abstract ell2",domProps:{"innerHTML":_vm._s(item.abstract)}}):_vm._e(),_c('div',{staticClass:"keywords-firstPubTime"},[(item.keywords && _vm.showKeywords)?_c('div',{staticClass:"keywords",domProps:{"innerHTML":_vm._s('关键词：' + item.keywords)}}):_vm._e(),(item.firstPubTime && _vm.showFirstPubTime)?_c('div',{staticClass:"firstPubTime",domProps:{"innerHTML":_vm._s('发表时间：' + item.firstPubTime)}}):_vm._e()])])}),_c('Pagination',{attrs:{"show-total":false,"init-obj":_vm.tablePageObj},on:{"onChangePageData":_vm.handleChangePageData}})],2):_c('div',{staticClass:"no-data"},[_vm._v("暂无数据")])]):_vm._e(),(_vm.listTableType === 'table')?_c('commonTable',{attrs:{"is-click":false,"show-table-pagination":false,"table-data":_vm.tableData,"column-data":_vm.tableColumn,"init-obj":_vm.tablePageObj},on:{"onClickTitle":_vm.handleOpenUrl,"onChangePageData":(pageObj) => {
          _vm.handleChangePageData(pageObj)
        }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }